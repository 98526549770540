import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { Formik, Form, Field } from "formik";
import { useAppDispatch } from "store/configureStore";
import {signInUser} from "redux/accountSlice";

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";
function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
   
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const result = await dispatch(signInUser(values));
      if (result.meta.requestStatus === "fulfilled") {
        navigate('/dashboard');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Admin Panel
          </MDTypography>
        </MDBox>
        <Formik
           initialValues={{ 
             username: "",
             password: "",
           }}
          onSubmit={
            async (values) => {
              await handleSubmit(values);
           }
            }
          >
        {({
           values,
           errors,
           touched,
           setFieldValue,
           setFieldTouched,
           handleChange,
           handleBlur,
           handleSubmit,
           isSubmitting
         }) => (
          <Form>
        <MDBox pt={4} pb={3} px={3}>
            <MDBox mb={2}>
            <Field
              type="text"
              name="username"
              id="username"
              label="İstifadəçi adı"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              as={MDInput}
              variant="standard"
              fullWidth
            />
            </MDBox>
            <MDBox mb={2}>
           <Field
             type="password"
             name="password"
             id="password"
             label="Şifrə"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.password}
             as={MDInput}
             variant="standard"
             fullWidth
           />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
              disabled={loading}
              type="submit"
              variant="gradient"
              color="info"
              fullWidth
              >
               Giriş et
              </MDButton>
            </MDBox>
        </MDBox>
        </Form>
          )}
       </Formik>
      </Card>
    </BasicLayout>
  );
}

export default Login;
