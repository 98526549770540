import { configureStore } from '@reduxjs/toolkit'
import {useDispatch, useSelector} from "react-redux";
import tableDataSlice from "redux/tableDataSlice";
import accountSlice from "redux/accountSlice";
export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
    reducer:{
        account:accountSlice,
        tableData: tableDataSlice,
    }
})

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;


