import  axios,{AxiosError} from "axios";

const sleep = () => new Promise(resolve => setTimeout(resolve, 500));
axios.defaults.baseURL = "https://api.beautyindustry.works/";
const responseBody = (response) => response.data;
import {store} from "store/configureStore";

axios.interceptors.request.use((config) => {
    const token = store.getState().account.token?.accessToken;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})

axios.interceptors.response.use(
  async (response) => {
    await sleep();
    return response;
  },
  (error) => {
     const { data, status } = error?.response;
        switch (status) {
            case 400:
               if (data.title === "Bad Request") {
                    alert(data.detail);
                }
                if (data.errors) {
                    const modelStateErrors = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modelStateErrors.push(data.errors[key])
                        }
                    }
                    throw modelStateErrors.flat();
                }
                break;
         case 401:
             break;
         case 500:
           alert("Server error - check the terminal for more info!");
             break;
         case 404:
              alert("Not found");
             break;
         default:
             break;
     }
     return Promise.reject(error.response);
  }
);

const requests = {
  get: (url) => axios.get(url , {  
         headers: {
           "ngrok-skip-browser-warning": "any",
         },
        }
    ).then(responseBody),

  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  delete: (url) => axios.delete(url).then(responseBody),
  del: (url, body) => axios.delete(url, { data: body }).then(responseBody),
  postForm: (url, data) => axios.post(url, data, {
    headers: {'Content-type': 'multipart/form-data'}
}).then(responseBody),
putForm: (url, data) => axios.put(url, data, {
    headers: {'Content-type': 'multipart/form-data'}
}).then(responseBody)
};

function createFormData(item) {
    let formData = new FormData();
    for (const key in item) {
            formData.append(key, item[key]);
        }
    return formData;
}


const Account = {
    login: (value) => requests.post("api/Auth/Login", value),
}

const User = {
  customersByManager: (managerId) =>
    requests.get(`api/admin/Customer/customers-by-manager/`+managerId),
  createCustomer: (customer) => requests.post(`api/Customers/AddCustomer`, customer),
  create: (warehouseman) => requests.post(`api/admin/UserManagement/AddWarehouseman`, warehouseman),
  listWarehouseman: () => requests.get(`api/admin/UserManagement/Warehousemen`),
  update : (values) => requests.put(`api/admin/UserManagement/UpdateUser`, values),
  addCustomer: (values) => requests.post(`api/employee/Customer/AddCustomer`, values),
  listCustomers: () => requests.get(`api/employee/Customer/Customers`),
  minimizeCustomers: () => requests.get("api/admin/Customer/customers/minimize"),
  delete: (id) => requests.del(`api/employee/Customer/delete/${id}`),
  totalDebtForAllCustomer: (value = null) => requests.get("api/admin/Customer/GetTotalDebt",value),
};

const Managers = {
  list: (pageNumber, pageSize) =>
    requests.get(
      `api/admin/UserManagement/Managers?pageNumber=` + pageNumber + "&pageSize=" + pageSize
    ),
  allmanagers: () => requests.get(`api/admin/UserManagement/AllManagers`),
  create: (manager) =>
    requests.post(`api/admin/UserManagement/AddManager`,manager),
  delete: (userIds) => requests.del(`api/admin/UserManagement`, userIds),
};


const Category = {
  list: () => requests.get("api/admin/Category/Categories"),
  create: (category) => requests.post(`api/admin/Category/AddCategory`, category),
  update: (category) => requests.put(`api/admin/Category/UpdateCategory`, category),
  delete: (id) => requests.delete(`api/admin/Category/DeleteCategory/` + id),
};

const Sale = {
  create: (sale) => requests.post(`api/employee/Sale/CreateSale`, sale),
  delete: (id) => requests.delete(`api/admin/Sale/delete/${id}`),
  update: (sale) => requests.put(`api/admin/Sale/UpdateSale`, sale),
}

const Payment = {
  create: (payment) => requests.post(`api/admin/Payment/AddPayment`, payment),
  debtPayment: (payment) => requests.post(`api/employee/Sale/DebtPayment`, payment),
  delete: (id) => requests.delete(`api/admin/Sale/payment/delete/${id}`),
  update: (payment) => requests.put(`api/admin/Payment/UpdatePayment`, payment),
}


const Product = {
  handoverProduct: (values) => requests.post(`api/admin/Product/HandoverProduct`, values),
  create: (product) => requests.post(`api/admin/Product/AddProduct`, product),
  delete: (id) => requests.delete(`api/admin/Product/delete/${id}`),
  allProducts: () => requests.get(`api/admin/Product/AllProducts`),
  updateProduct: (product) => requests.put(`api/admin/Product/UpdateProduct`, product),
  returnProduct: (values) => requests.post(`api/admin/Product/ReturnProduct`, values),
  updateStock: (values) => requests.put(`api/admin/Product/UpdateStock`, values),
  productInventory: () => requests.get(`api/Product/productInventory`),
  removeTransactionReport:(id) => requests.delete(`api/admin/Report/inventory-transaction-report/remove/${id}`),
  debtorProducts: (id) => requests.get(`api/employee/Product/DebtorProducts?Id=${id}`),
  purchasedProducts: (id) => requests.get(`api/employee/Product/purchased-products/${id}`),
  returnProductFromCustomer: (values) => requests.post(`api/employee/Product/ReturnProduct`, values),
  inportFromExcel: (values) => requests.postForm(`api/admin/Product/inport/from-xml`, createFormData(values)),
};

const CashRegister = {
  spendOrAddMoney : (values) => requests.post(`api/admin/CashRegister/spend-or-add-money`, values),
  addModenToHistory: (values) => requests.post(`api/admin/CashRegister/add-money-to-history`, values),
  delete: (id) => requests.delete(`api/admin/CashRegister/delete-cash-register?Id=${id}`),
};

const agent ={
    Account,
    User,
    Category,
    Product,
    Managers,
    CashRegister,
    Sale,
    Payment
}

export default agent ;