// react-router components
import { Routes, Route, Navigate} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import React, {useEffect, useState} from 'react';

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";


import CategoryForm from "layouts/category/CategoryForm";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";


// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController,setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct.png";
import ProductForm from "layouts/product/ProductForm";
import Login from "layouts/authentication/sign-in";

import { useAppDispatch } from "store/configureStore";
import { setToken } from "redux/accountSlice";
import {PrivateRoute}  from "layouts/authentication/PrivateRoute";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const [loading,setLoading] = useState(false);
  const dispatchAP = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(true);
      return;
    }
    dispatchAP(setToken(JSON.parse(token)));
    setLoading(true);
  }, []);

  const {
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;


 let  RouteArray = [];
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if(route.items && route.items.length > 0) {
         route.items.map((item) => {
         RouteArray.push(<Route exact path={item.route} element={<PrivateRoute>{item.component}</PrivateRoute>} key={item.key} />);
      })}
      else{
         RouteArray.push(<Route exact path={route.route} element={<PrivateRoute>{route.component}</PrivateRoute>} key={route.key} />);
      }
      return RouteArray.length > 0 ? RouteArray : <React.Fragment></React.Fragment>;
    });

  if(!loading) {  
    return <div>Loading...</div>;
  }

  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <PrivateRoute>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="Beauty Industry"
                  routes={routes}
                />
                <Configurator />
                {layout === "vr" && <Configurator />}
              </PrivateRoute>
            </>
          )}
          <Routes>
            {getRoutes(routes)}
            <Route
              exact
              path="*"
              element={
                <PrivateRoute>
                  <Navigate to="/dashboard" />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/login"
              element={
                <PrivateRoute>
                  <Login />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/category/edit"
              element={
                <PrivateRoute>
                  <CategoryForm type="edit" />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/product/edit"
              element={
                <PrivateRoute>
                  <ProductForm type="edit" />
                </PrivateRoute>
              }
            />
          </Routes>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}
