import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
// Formik and Yup validation
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Ibeauty Admin Agent
import agent from "api/agent";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import {useNavigate} from "react-router-dom";
import { Input } from "antd";


function ProductForm({ type }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const [addFromExcel, setAddFromExcel] = useState(false);

  const initialValues = {
    productId: "",
    name: "",
    price: 0,
    cost:0,
    quantity: 1,
    cashCommission: 0,
    categoryId: "",
    isPrimary: true,
    excelFile: null,
    dateTime: new Date(),
  };


  const errorMessages = (error) => {
    return (
      <MDBox component="span" color="error.main">
        {error}
      </MDBox>
    );
  };

  const [categories, setCategories] = React.useState([]);
  React.useEffect(() => {
     if(!addFromExcel) {
       agent.Category.list().then((response) => {
      const categories = response?.map((category) => {
        return {
          value: category.id,
          label: category.name
        };
      });
      setCategories(categories);
    })
      }
  }, [addFromExcel]);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    if(addFromExcel) {
      agent.Product.inportFromExcel(values).then(() => {
        actions.setSubmitting(false);
        setSuccessSB(true);
        actions.resetForm();
        setLoading(false);
        navigate('/product/products');
      })
      .catch((err) => {
        setLoading(false);
        actions.setSubmitting(false);
      });
    }else {
      agent.Product.create(values)
      .then(() => {
        actions.setSubmitting(false);
        setSuccessSB(true);
        actions.resetForm();
        setLoading(false);
        navigate('/product/products');
      })
      .catch((err) => {
        setLoading(false);
        actions.setSubmitting(false);
      });
    }
  };

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Yeni Məhsul
                </MDTypography>
              </MDBox>

              <MDBox pt={4} pb={4} px={25}>
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  onSubmit={(values, actions) => handleSubmit(values, actions)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                      <>
                      <MDBox 
                      mb={2}
                      sx={{ fontWeight: "bold" , fontSize: "1.0rem" , color: "info.main",marginBottom: "1rem" , borderBottom: "1px solid #ccc" , paddingBottom: "1rem" }}
                      >
                      <MDTypography
                        variant="body2"
                        component="div"
                        sx={{ fontWeight: "bold" , fontSize: "1.0rem" , color: "info.main" , marginTop: "1rem" ,paddingTop: "1rem" }}
                        mt={2}
                        mb={2}
                      >
                        Məhsul əlavə etmək üçün nə istifadə etmək istəyirsiniz?
                      </MDTypography>
                      <RadioGroup
                         row
                         aria-labelledby="demo-row-radio-buttons-group-label"
                         name="row-radio-buttons-group"
                       >
                        <FormControlLabel 
                         value="1"
                         control={<Radio />} 
                         checked={!addFromExcel}
                         sx={
                            {
                              color: "",
                              fontWeight: "bold",
                              fontSize: "1.0rem",
                            }
                         }
                         label="Manual Əlavə"
                          onChange={() => setAddFromExcel(false)}
                         />
                         <FormControlLabel 
                         value="2"
                         control={<Radio />} 
                          checked={addFromExcel}
                         label="Excel ilə Əlavə"
                          onChange={() => setAddFromExcel(true)}
                          />
                       </RadioGroup>
                        </MDBox>
                      <div>Seciminiz : {addFromExcel ? "Excel ilə əlavə etmək" : "Manual əlavə etmək"}</div>
                      <MDTypography
                        variant="body2"
                        component="div"
                        color="#ccc"
                        mt={2}
                        mb={2}
                      >
                        {addFromExcel ? "Excel ilə əlavə etmək üçün fayl seçin" : "Məhsulun məlumatlarını daxil edin"}
                      </MDTypography>
                       <MDBox component="div" role="form">
                        {!addFromExcel ? (
                         <>
                         <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                label="Məhsulun adı"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                as={MDInput}
                                variant="standard"
                                fullWidth
                              />
                              {}
                              {touched.name && errors.name && errorMessages(errors.name)}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <Field
                                name="categoryId"
                                label="Kateqoriya"
                                component={SelectBox}
                                options={categories ? categories : []}
                                onChange={(value) => setFieldValue("categoryId", value)}
                              />
                              {touched.categoryId &&
                                errors.categoryId &&
                                errorMessages(errors.categoryId)}
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <Field
                                type="number"
                                name="cost"
                                step="any"
                                id="cost"
                                label="Maya qiyməti"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cost}
                                as={MDInput}
                                variant="standard"
                                fullWidth
                              />
                              {touched.cost &&
                                errors.cost &&
                                errorMessages(errors.cost)}
                            </MDBox>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <Field
                                type="number"
                                name="price"
                                step="any"
                                id="price"
                                label="Satış qiyməti"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                                as={MDInput}
                                variant="standard"
                                fullWidth
                              />
                              {touched.price && errors.price && errorMessages(errors.price)}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <Field
                                type="number"
                                name="quantity"
                                label="Stok sayı"
                                as={MDInput}
                                variant="standard"
                                fullWidth
                                value={values.quantity}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <Field
                                type="number"
                                name="cashCommission"
                                label="Nağd komissiya"
                                as={MDInput}
                                variant="standard"
                                fullWidth
                                value={values.cashCommission}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <Field
                                type="datetime-local"
                                name="dateTime"
                                label="Tarix"
                                as={MDInput}
                                variant="standard"
                                fullWidth
                                value={values.dateTime}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                         </>
                        ) : (
                          <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <Input
                                type="file"
                                name="excelFile"
                                id="excelFile"
                                label="Excel fayl"
                                onChange={(event) => {
                                  setFieldValue("excelFile", event.currentTarget.files[0]);
                                }}
                                variant="standard"
                                fullWidth
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                               <FormControlLabel
                                 control={<Checkbox />} 
                                 label="Əvvələ qalıq"
                                  checked={values.isPrimary}
                                  onChange={(event) => {
                                    setFieldValue("isPrimary", event.currentTarget.checked);
                                  }}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </MDBox>
                          </Grid>
                        </Grid>
                        )}
                        
                        <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton
                            disabled={isSubmitting}
                            sx={{ 
                              backgroundColor: isSubmitting ? "info.light" : "info.main",
                             }}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                            size="large"
                            onClick={handleSubmit}
                          >
                           {isSubmitting ? "Əlavə edilir..." : "Əlavə et"}
                          </MDButton>
                        </MDBox>
                        <MDBox mt={1} mb={1} style={{ display: isSubmitting ? "block" : "none" }}>
                          <LinearProgress
                            color="info"
                            hidden={true}
                            style={{
                              width: "50%",
                              margin: "auto",
                              position: "relative",
                            }}
                          />
                        </MDBox>
                      </MDBox>
                      </>
                  )}
                </Formik>
                <MDSnackbar
                  color="success"
                  icon="check"
                  title="Uğurlu Əməliyyat"
                  content="Məhsul əlavə edildi"
                  dateTime={new Date().toLocaleString().split(",")[0]}
                  open={successSB}
                  onClose={closeSuccessSB}
                  close={closeSuccessSB}
                  bgWhite
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ProductForm;
