function getUserRoleFromToken() {
    const token = localStorage.getItem('token');

    if (token) {
        try {
            const jwtPayload = JSON.parse(atob(token.split('.')[1]));
            const userRole = jwtPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

            return userRole;
        } catch (error) {
            console.error('Token decode hatası:', error);
            return null;
        }
    } else {
        console.error('Token bulunamadı.');
        return null;
    }
}

export default getUserRoleFromToken;
