import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Formik, Form, Field } from "formik";

// Ibeauty Admin Agent
import agent from "api/agent";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import getUserRoleFromToken from "utils/getUserRoleFromToken";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";


function WarehousemanForm({ type }) {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  // get all companies from backend and add to SelectBox options

  const handleSubmit = (values, actions) => {
    setLoading(true);
    agent.User[type](values)
      .then(() => {
        actions.setSubmitting(false);
        setSuccessSB(true);
        actions.resetForm();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Anbardar Əlavə Et
                </MDTypography>
              </MDBox>
              
                 {
                  getUserRoleFromToken() === "Administrator" || "Admin" ?  <MDBox pt={4} pb={4} px={25}>
                  <Formik
                    initialValues={{
                      id: type === "create" ? null : location.state.id,
                      userName: type === "create" ? "" : location.state.userName,
                      nameSurname: type === "create" ? null : location.state.nameSurname,
                      password: type === "create" ? null : location.state.password,
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({ values, handleChange,handleBlur }) => (
                      <Form>
                        <MDBox component="div" role="form">
                          <MDBox mb={2}>
                            <Field
                              type="text"
                              name="userName"
                              label="Anbardarın adı və soyadı"
                              id="userName"
                              values={values.userName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              as={MDInput}
                              variant="standard"
                              fullWidth
                            />
                          </MDBox>
                          <MDBox mb={2}>
                            <Field
                              type="text"
                              name="nameSurname"
                              id="nameSurname"
                              values={values.nameSurname}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label="Anbardarın adı"
                              as={MDInput}
                              variant="standard"
                              fullWidth
                            />
                          </MDBox>
                          <MDBox mb={2}>
                            <Field
                              type="password"
                              name="password"
                              label="Şifrə"
                              id="password"
                              values={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              as={MDInput}
                              variant="standard"
                              fullWidth
                            />
                          </MDBox>
                          <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
                          <MDBox mt={4} mb={1}>
                            <MDButton
                              disabled={loading}
                              type="submit"
                              variant="gradient"
                              color="info"
                              fullWidth
                            >
                              {type === "create" ? "Əlavə Et" : "Yenilə"}
                            </MDButton>
                          </MDBox>
                          <MDBox mt={1} mb={1} style={{ display: loading ? "block" : "none" }}>
                            <LinearProgress
                              color="info"
                              hidden={true}
                              style={{ width: "50%", margin: "auto", position: "relative" }}
                            />
                          </MDBox>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
                : <h1>Yalnız Administratorlər bu səhifəyə baxa bilər</h1>
                 }
              <MDSnackbar
                color="success"
                icon="check"
                title="Uğurlu Əməliyyat"
                content={"Anbardar uğurla " + (type === "create" ? "əlavə edildi" : "yeniləndi")}
                dateTime={new Date().toLocaleString().split(",")[0]}
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default WarehousemanForm;
