/** 
  All of the routes for the Ibeauty Admin Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Beauty Industry Admin Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Categories from "layouts/category";
import Managers from "layouts/users/Managers";
import Products from "layouts/product";
// @mui icons
import Icon from "@mui/material/Icon";
import CategoryForm from "layouts/category/CategoryForm";
import ProductForm from "layouts/product/ProductForm";
import ManagerForm from "layouts/users/Managers/ManagerForm";
import WarehousemanForm from "layouts/users/Warehouseman/WarehousemanForm";
import Warehouseman from "layouts/users/Warehouseman";
import Customers from "layouts/customer";
import Warehouses from "layouts/warehouse";
import ProductInventory from "layouts/product/ProductInventory";
import CashRegisterReport from "layouts/Reports/cashregisterreport";
import ProductMovementReport from "layouts/Reports/productmovementreport";
import SalesReport from "layouts/Reports/salesreport";
import SellForm from "layouts/sale/sell/SellForm";
import Return from "layouts/sale/retun";
import DebtPayment from "layouts/sale/debtpayment";


const routes = [
  {
    type: "collapse",
    name: "İdarəetmə Paneli",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Satış",
    key: "sale",
    route: "/sale",
    icon: <Icon fontSize="small">sell</Icon>,
    items: [
      {
        type: "collapse",
        name: "Satış Əməliyyatı",
        key: "makeasale",
        route: "/sale/makeasale",
        component: <SellForm/>,
        icon: <Icon color="blue"  fontSize="medium">
                shopping_cart_checkout
              </Icon>,
      },
      {
        type: "collapse",
        name: "Geri Qaytarma",
        key: "return",
        route: "/sale/return",
        component: <Return/>,
        icon: <Icon fontSize="medium" color="green" style={{ transform: "scaleX(-1)" }}>
             shopping_cart_checkout
             </Icon>,
      },
      {
        type: "collapse",
        name: "Borc Ödənişi",
        key: "debtpayment",
        route: "/sale/debtpayment",
        component: <DebtPayment/>,
        icon: <Icon fontSize="small">payments</Icon>,
      },
    ],
  },
  {
    type: "collapse",
    name: "Müştərilər",
    key: "customer",
    route: "/customer/customers",
    icon: <Icon fontSize="small">groups</Icon>,
    items: [

      {
        type: "item",
        name: "Bütün Müştərilər",
        key: "customers",
        route: "/customer/customers",
        icon: <Icon fontSize="small">format_list_numbered_rtl</Icon>,
        component: <Customers />,
      }
    ],
  },
  {
    type: "collapse",
    name: "Kateqoriyalar",
    key: "category",
    icon: <Icon fontSize="small">toc</Icon>,
    items: [
      {
        type: "item",
        name: "Kateqoriya Əlavə Et",
        key: "add-category",
        route: "/category/add",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <CategoryForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Kateqoriyalar",
        key: "categories",
        route: "/category/categories",
        // list icon
        icon: <Icon fontSize="small">list</Icon>,
        component: <Categories />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Məhsullar",
    key: "product",
    icon: <Icon fontSize="small">local_mall</Icon>,
    items: [
      {
        type: "item",
        name: "Məhsul Əlavə Et",
        key: "add-product",
        route: "/product/add",
        icon: <Icon fontSize="small">add_circle</Icon>,
        component: <ProductForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Məhsullar",
        key: "products",
        route: "/product/products",
        // list icon
        icon: <Icon fontSize="small">list</Icon>,
        component: <Products />,
      }
    ],
  },
  {
    type: "collapse",
    name: "Sənədlər",
    key: "documents",
    icon: <Icon fontSize="small">folder_open</Icon>,
    items: [
      {
        type: "item",
        name: "Satış Hesabatı",
        key: "sales-report",
        route: "/report/sales-report",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        component: <SalesReport/>,
      },
      {
        type: "item",
        name: "Kassa Hesabatı",
        key: "cashregister-report",
        route: "/report/cashregister-report",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        component: <CashRegisterReport />,
      },
      {
        type: "item",
        name: "Anbar Sənədi",
        key: "warehouses-report",
        route: "report/warehouses",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        component: <Warehouses />,
      },
      {
        type: "item",
        name: "Məhsulların hərəkəti cədvəli",
        key: "product-movement-report",
        route: "/report/product-movement-report",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        component: <ProductMovementReport/>,
      },
      {
        type: "item",
        name: "Məhsulların Anbardakı Qalığı",
        key: "product-inventory-report",
        route: "/report/inventory-report",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        component: <ProductInventory />
      },
    ],
  },
  {
    type: "collapse",
    name: "Şirkət Çalışanları",
    key: "employees",
    icon: <Icon fontSize="small">diversity_3</Icon>,
    items: [
      {
        type: "item",
        name: "Menecer Əlavə Et",
        key: "add-menecer",
        route: "/user/manager/add",
        icon: <Icon fontSize="small">person_add</Icon>,
        component: <ManagerForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Menecerlər",
        key: "managers",
        route: "/user/manager/managers",
        icon: <Icon fontSize="small">format_list_numbered_rtl</Icon>,
        component: <Managers />,
      },
      {
        type: "item",
        name: "Anbardar Əlavə Et",
        key: "add-warehouseman",
        route: "/user/warehouseman/add",
        icon: <Icon fontSize="small">person_add</Icon>,
        component: <WarehousemanForm type="create" />,
      },
      {
        type: "item",
        name: "Bütün Anbardarlar",
        key: "warehousemen",
        route: "/user/warehouseman/warehousemen",
        icon: <Icon fontSize="small">format_list_numbered_rtl</Icon>,
        component: <Warehouseman />,
      },
    ],
  },
  {
    type: "divider",
    name: "Divider",
    key: "divider",
  },
];

export default routes;
