import React, {useState } from "react";
// Formik and Yup validation
import { Formik, Form, Field} from 'formik';
import * as Yup from "yup";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
// Ibeauty Admin Agent
import agent from "api/agent";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";


function DebtPayment() {
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const closeSuccessSB = () =>  {
    errorSB ? setErrorSB(false) : setSuccessSB(false);
  }

  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCustomersId, setSelectedCustomersId] = useState(null);

  const initialValues = {
    customerId: "",
    productId: "",
    amount: 1,
    comment: "",
    dateTime: new Date(),
  };


  const errorMessages = (error) => {
    return (
       <MDTypography 
        variant="body2"
        color="error"
        textTransform="capitalize"
        >
        {error}
        </MDTypography>
    );
  };
  const validationSchema = Yup.object().shape({
    customerId: Yup.string()
      .required("Müştəri ID si tələb olunur")
      .matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, "Yanlış GUID formatı"),
    
    productId: Yup.string()
      .required("Məhsul ID si tələb olunur")
      .matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, "Yanlış GUID formatı"),
    
    amount: Yup.number()
        .required("Ədəd tələb olunur")
        .min(1, "Ödəniş 0 dan böyük olmalıdır")
        .nullable(false),
    comment: Yup.string()
        .nullable(true)
        .max(100, "Maksimum 100 simvol daxil edə bilərsiniz")
  });
  

  const [loadCustomers, setLoadCustomers] = useState(false);
  const fetchCustomers = async () => {
    setLoadCustomers(true);
    try
    {
      const customers = await agent.User.minimizeCustomers()
      setCustomers(customers);
      setLoadCustomers(false);
    }
    catch(error){
        console.log(error);
        setLoadCustomers(false);
    }
};

const [loadProducts, setLoadProducts] = useState(false)

const fetchProducts = async (id) => {
    setLoadProducts(true);
    try
    {
      const products = await agent.Product.debtorProducts(id)
        setProducts(products);
        setLoadProducts(false);
    }
    catch(error){
        console.log(error);
        setLoadProducts(false);
    }
};

React.useEffect(() => {
    if(selectedCustomersId)
    {
        fetchProducts(selectedCustomersId);
    }
}, [selectedCustomersId]);

React.useEffect(() => {
    fetchCustomers();
  }, []);

const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
     try
     {
        const returnProduct = await agent.Payment.debtPayment(values);
        if(returnProduct)
        {
        setSuccessSB(true);
        actions.resetForm();
        setProducts([]);
        }else{
            setErrorSB(true);
        }
        actions.setSubmitting(false);
      }
    catch(error){
            console.log(error);
            setErrorSB(true);
            actions.setSubmitting(false);
     }
  };

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                    Borc Ödənişi
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={4} px={25}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => await handleSubmit(values, actions)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form>
                      <MDBox component="div" role="form">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <Field
                               disabled={loadCustomers || isSubmitting}
                               required
                                name="customerId"
                                label="Müştəri"
                                component={SelectBox}
                                options={customers && customers.map((customer) => ({
                                    value: customer.id,
                                    label: `${customer.nameSurname} ${" "}- Manager: ${customer.managerName}`
                                }))}
                                onChange={(value) => {
                                    setFieldValue("customerId", value)
                                    setSelectedCustomersId(value)
                                }}
                              />
                              {touched.customerId &&
                                errors.customerId &&
                                errorMessages(errors.customerId)}
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <Field
                                disabled={loadProducts || isSubmitting}
                                required
                                name="productId"
                                label="Məhsul"
                                component={SelectBox}
                                options={products && products.map((product) => ({
                                    value: product.id,
                                    label: product.name +" "+ `${product.debt} AZN`
                                }))}
                                
                                onChange={(value) => setFieldValue("productId", value)}
                              />
                              {touched.productId &&
                                errors.productId &&
                                errorMessages(errors.productId)}
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                              <Field
                                required
                                disabled={isSubmitting}
                                type="number"
                                name="amount"
                                step="any"
                                id="amount"
                                label="Məbləğ"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.amount}
                                as={MDInput}
                                variant="standard"
                                fullWidth
                              />
                            {touched.amount && errors.amount && errorMessages(errors.amount)}
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDBox mb={2}>
                                <Field
                                    disabled={isSubmitting}
                                    name="comment"
                                    label="Qeyd"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.comment}
                                    as={MDInput}
                                    variant="standard"
                                    fullWidth
                                />
                                {touched.comment && errors.comment && errorMessages(errors.comment)}
                            </MDBox>
                         </Grid>
                         <Grid item xs={12} md={4}>
                            <MDBox mb={2}>
                              <Field
                                type="datetime-local"
                                name="dateTime"
                                label="Tarix"
                                as={MDInput}
                                variant="standard"
                                fullWidth
                                value={values.dateTime}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="info"
                            fullWidth
                          >
                            {isSubmitting ? "Gözləyin..." : "Ödəniş et"}
                          </MDButton>
                        </MDBox>
                        <MDBox mt={1} mb={1} style={{ display: isSubmitting ? "block" : "none" }}>
                          <LinearProgress
                            color="info"
                            hidden={true}
                            style={{
                              width: "50%",
                              margin: "auto",
                              position: "relative",
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
                <MDSnackbar
                  color={errorSB === true  && successSB === false ? "error" : "success"}
                  icon="check"
                  title={errorSB ? "Xəta baş verdi" : "Uğurlu Əməliyyat"}
                  content={errorSB ? "Ödəniş edərkən xəta baş verdi" : "Ödəniş uğurla tamamlandı"}
                  dateTime={new Date().toLocaleString().split(",")[0]}
                  open={successSB || errorSB}
                  onClose={closeSuccessSB}
                  close={closeSuccessSB}
                  bgWhite
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default DebtPayment;
