import Dashboard from 'layouts/dashboard';
import {store} from "store/configureStore";
import Login from './sign-in';

export const PrivateRoute = ({ children }) => {
    const token = store.getState().account.token?.accessToken;
    const childrenName = children?.type?.name;
     switch (childrenName) {
         case "Login":
                return token ? <Dashboard/> : children;
         case "Register":
                return token ? <Dashboard/> : children;
         default:
                return token ? children : <Login/>;
        }
  };