import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { random } from "chroma-js";

const SelectBox = ({ options, value,defaultValue, onChange, label,style}) => {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue?.value );
      }}
      style={style ? style : { width: "100%" }}
      defaultValue={defaultValue}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField 
        {...params} 
        label={label} 
        variant="outlined"
         />
      )}
    />
  );
};

export default SelectBox;