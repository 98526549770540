import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { IconButton, Tooltip, Button, Toolbar } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import axios from "axios";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//Date Picker Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL("api/admin/Product/ProductMovements", "https://api.beautyindustry.works");
      fetchURL.searchParams.set('start',`${pagination.pageIndex + 1}`,);
      fetchURL.searchParams.set('size',`${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      const response = await axios.get(fetchURL.href, {
        headers: {
          "ngrok-skip-browser-warning": "any",
        },
      });
      return response.data;
    },
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "documentName",
        header: "Sənədin adı",
        id: "DocumentName",
      },
      {
        accessorFn: (row) => new Date(row.date), //convert to Date for sorting and filtering
        id: "Created",
        header: "Başlama tarixi",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mui/x-date-pickers
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "productName",
        header: "Məhsul adı",
        id: "Product.Name",
      },
      {
        accessorKey: "quantity",
        header: "Miqdar",
        id: "Quantity",
      },
      {
        accessorKey: "from",
        header: "Əsas Anbar",
        id: "From",
      },
      {
        accessorKey: "to",
        header: "Hədəf Anbar",
        id: "To",
      },
      {
        accessorKey: "executor",
        header: "İcraçı",
        id: "Executor",
      },
    ],
    []
  );

const rowsPerPageOptions = [];
for (let i = 10; i <= data?.totalCount; i += 10) {
  rowsPerPageOptions.push(i);
}
rowsPerPageOptions.push(data?.totalCount);

  return (
    <MaterialReactTable
      columns={columns}
      data={data?.items ?? []} //data is undefined on first render
      initialState={{ showColumnFilters: true }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      muiTablePaginationProps={{
        rowsPerPageOptions: rowsPerPageOptions,
        showFirstButton: true,
        showLastButton: true,
      }}
      rowCount={data?.totalCount ?? 0}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isFetching,
        sorting,
      }}
    />
  );
};

const Warehouses = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Anbar Sənədləri
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Warehouses;
