import React, { useMemo, useState , useCallback } from "react";
import MaterialReactTable from "material-react-table";
import { IconButton, Tooltip, Button, Toolbar } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {Edit, Visibility } from "@mui/icons-material";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import getUserRoleFromToken from "utils/getUserRoleFromToken";

import agent from "api/agent";

const Example = () => {
 
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  
  const fectWarehouseman = async () => {
    setIsLoading(true);
    setIsError(false);
    setIsFetching(true);
    try {
      const response = await agent.User.listWarehouseman();
      setTableData(response);
      setIsFetching(false);
      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setIsFetching(false);
      setIsLoading(false);
      setIsError(true);
    }
  };

  const refetch = useCallback(() => {
    fectWarehouseman();
  }, []);

  React.useEffect(() => {
    fectWarehouseman();
  }, []);


  
  const columns = useMemo(
    () => [
      {
        accessorKey: "nameSurname",
        header: "Ad Soyad",
        id : "NameSurname"
      },
      {
        accessorKey: "userName",
        header: "Istifaçı adı",
        id : "UserName"
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={tableData ??  []}
      enableColumnFilterModes
      enableColumnOrdering
      enableGrouping
      enablePinning
      enableRowActions
      enableRowSelection
      initialState={{ showColumnFilters: true }}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      renderRowActions={({ row }) => (
        <Toolbar>
          <IconButton onClick={() => alert(row.original.nameSurname)}>
            <Visibility />
          </IconButton>
          <IconButton onClick={() => alert(row.original.nameSurname)}>
            <Edit />
          </IconButton>
        </Toolbar>
      )}
      renderTopToolbarCustomActions={({ table }) => {
        const handleDeactivate = () => {
          let selected = table.getSelectedRowModel().flatRows.map((row) => {
            return row.original.id;
          });
          alert(JSON.stringify(selected));
        };

        return (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button
              color="error"
              disabled={!table.getIsSomeRowsSelected()}
              onClick={handleDeactivate}
              variant="contained"
            >
              Silmək
            </Button>
            <Tooltip arrow title="Refresh Data">
              <IconButton onClick={() => refetch()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      }}
      state={{
        isLoading,
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
    />
  );
};

const Warehouseman = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Anbardarlar
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
            {
               getUserRoleFromToken() === "Administrator" || "Admin" ? <Example /> : <h1>Yalnız Administratorlər bu səhifəyə baxa bilər</h1>
             }
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Warehouseman;
