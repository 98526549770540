import React, { useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    IconButton,
    Tooltip,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
  } from "@mui/material";
  import {Delete} from "@mui/icons-material";
  import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from "@mui/icons-material/Refresh";
// react query 
import { useQuery } from "@tanstack/react-query";
// yup
import * as yup from "yup";

import dayjs, { Dayjs } from 'dayjs';

import { Formik, Form, Field } from "formik";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import axios from "axios";

import agent from "api/agent";

import CircularProgress from '@mui/material/CircularProgress';

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//Date Picker Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);


  React.useEffect(() => {
    if(columnFilters.length === 0){
      setColumnFilters([
        {
          id:"Created",
          value: [dayjs(new Date()).toISOString(), dayjs(new Date()).toISOString()]
        }
      ])
    }
}, [])


  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/admin/Report/cash-register-report",
        "https://api.beautyindustry.works"
      );
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      const response = await axios.get(fetchURL.href, {
        headers: {
          "ngrok-skip-browser-warning": "any",
        },
      });
      return response?.data ?? [];
    },
    keepPreviousData: true,
  });

   React.useMemo( 
    () => {
        if (data?.data) {
            setTotalIncome(data?.data?.reduce((sum, row) => sum + row.income, 0));
            setTotalExpenses(data?.data?.reduce((sum, row) => sum + row.expenses, 0));
            if (data?.length > 1){
              setTotalBalance(data?.data[data?.length -2]?.balance ?? 0);
            }
          let totalPositiveProfit = data?.data?.reduce((sum, row) => {
              if (row.profit > 0) {
                  return sum + row.profit;
              }
              return sum;
          }, 0);
          
          let totalNegativeProfit = data?.data?.reduce((sum, row) => {
              if (row.profit < 0) {
                  return sum + row.profit;
              }
              return sum;
          }, 0);
          let correctedTotalProfit = totalPositiveProfit - Math.abs(totalNegativeProfit);
          setTotalProfit(correctedTotalProfit);
        }
        // add first row to data
        if (data?.data){
          if (data.data[0]?.date !== "Əvvələ Qalıq"){
            const newData = {
              "date": "Əvvələ Qalıq",
              "balance": data?.lateMoney,
            }
              data?.data.unshift(newData);
          }
        }
    },
    [data]
    );

   const columns = useMemo(
     () => [
       {
        accessorFn: (row) =>
         row?.date === "Əvvələ Qalıq" ? (
                 row?.date
                ) : (
                    new Date(row?.date )
                ),
        header: "Tarix",
        id: "Created",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
         cell.getValue() === "Əvvələ Qalıq" ? (
            <MDBox component="span" fontWeight="medium">
                {cell.getValue()}
            </MDBox>
            ) : (
                <MDBox component="span" fontWeight="medium">
                    {cell.getValue().toLocaleDateString()}
                </MDBox>
            )
         , 
         Filter: ({ column }) => {
          const [startDate, setStartDate] = React.useState(
           new Date()
          );
          const [endDate, setEndDate] = React.useState(
            new Date()
          );
        
          const handleDateChange = (newStartDate, newEndDate) => {
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        
            const newValue = [newStartDate.toISOString(), newEndDate.toISOString()];
            setColumnFilters([
              ...columnFilters.filter((filter) => filter.id !== column.id),
              { id: 'Created', value: newValue }
            ]);
          };
        
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div>
                <DatePicker
                  label="Başlangıç tarihi"
                  onChange={(newValue) => handleDateChange(newValue, endDate)}
                  value={dayjs(startDate)}
                />
                <DatePicker
                  label="Bitiş tarihi"
                  onChange={(newValue) => handleDateChange(startDate, newValue)}
                  value={dayjs(endDate)}
                />
              </div>
            </LocalizationProvider>
          );
        },
        Footer: () => (
            <MDBox textAlign="center">
                <MDTypography variant="h6">
                    Cəmi:
                </MDTypography>
            </MDBox>
        ),
       },
       {
         accessorKey: "income",
         header: "Mədaxil",
         id: "Income",
         columnFilterModeOptions: ["between", "greaterThan", "lessThan"], //only allow these filter modes
         filterFn: "between",
         Cell: ({ row }) => {
            const icome = row.original.income;
            return (
                    <MDTypography
                         variant="span"
                         fontWeight="small"
                         fontSize="1rem"
                         color= "white"
                         sx={{
                            display: "inline-block",
                            testAlign: "center",
                            borderRadius: "4px",
                            padding: icome > 0 ? "0.25rem 0.5rem" : "0rem",
                            backgroundColor: "success.main",
                            color: "white",
                            }}
                        >
                            {icome > 0 ? icome +  " AZN" : ""} 
                   </MDTypography>
            );
            },
         Footer: ({cell}) => {
                return (
                    <MDBox
                    component="span"
                    sx={{
                      display: "inline-block",
                      testAlign: "center",
                      borderRadius: "4px",
                      padding: "0.25rem 0.5rem",
                      backgroundColor:  "success.main",
                      color: "white",
                      }}
                     >
                        <MDTypography
                         variant="h6"
                         fontWeight="medium"
                         fontSize="1rem"
                         color="white"
                        >
                        {totalIncome} AZN
                        </MDTypography>
                    </MDBox>
                );
            },
       },
       {
         accessorKey: "expenses",
         header: "Məxaric",
         id: "Expenses",
         columnFilterModeOptions: ["between", "greaterThan", "lessThan"], //only allow these filter modes
         filterFn: "between",
            Cell: ({ row }) => {
                const expenses = row.original.expenses;
                return (
                    <MDTypography
                        variant="span"
                        fontWeight="small"
                        fontSize="1rem"
                        color= "white"
                        sx={{
                            display: "inline-block",
                            testAlign: "center",
                            borderRadius: "4px",
                            padding: expenses > 0 ? "0.25rem 0.5rem" : "0rem",
                            backgroundColor: "error.main",
                            color: "white",
                        }}
                    >
                        {expenses > 0 ? expenses +  " AZN" : ""}
                    </MDTypography>
                );
            },
         Footer: ({ column }) => {
                //custom footer markup
                const total = column.rows?.reduce(
                    (sum, row) => sum + row.values.expenses,
                     0
                );
                return (
                    <MDBox 
                    component="span"
                    sx={{
                        display: "inline-block",
                        testAlign: "center",
                        borderRadius: "4px",
                        padding: "0.25rem 0.5rem",
                        backgroundColor:  "error.main",
                        color: "white",
                    }}
                    >
                        <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            fontSize="1rem"
                            color="white"
                        >
                            {totalExpenses ?? 0} AZN
                        </MDTypography>
                    </MDBox>
                );
                }

       },
       {
         accessorKey: "balance",
         header: "Qalıq",
         id: "Balance",
         columnFilterModeOptions: ["between", "greaterThan", "lessThan"], //only allow these filter modes
         filterFn: "between",
            Cell: ({ row }) => {
                const balance = row.original?.balance;
                return (
                    <MDTypography
                        variant="span"
                        fontWeight="small"
                        fontSize="1rem"
                        color= "white"
                        sx={{
                            display: "inline-block",
                            testAlign: "center",
                            borderRadius: "4px",
                            padding: balance > 0 ? "0.25rem 0.5rem" : "0rem",
                            backgroundColor:  "info.main",
                            color: "white",
                        }}
                    >
                        {balance > 0 ? balance +  " AZN" : ""}
                    </MDTypography>
                );
            },
         Footer: ({ column }) => {
              //custom footer markup
              const total = column.rows?.reduce(
                (sum, row) => sum + row.values?.Balance,
                 0
              );
              return (
                <MDBox
                component="span"
                sx={{
                    display: "inline-block",
                    testAlign: "center",
                    borderRadius: "4px",
                    padding: "0.25rem 0.5rem",
                    backgroundColor:  "info.main",
                    color: "white",
                }}
                >
                    <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        fontSize="1rem"
                        color="white"
                    >
                        {totalBalance ?? 0} AZN
                    </MDTypography>
                </MDBox>
            );
            },
       },
       {
         accessorKey: "manager",
         header: "Menecer",
         id: "CreatedBy",
       },
       {
         accessorKey: "customer",
         header: "Müştəri",
         id: "Payment.Customer.NameSurname",
       },
       {
        accessorKey: "note",
        header: "Qeyd",
        id: "Note",
       },
     ],
    [totalIncome, totalExpenses, totalBalance]
   );
// Spend or Add Money Dialog
const CreateDiscountModal = ({ open, onClose ,action}) => {
    const handleSubmit = async (values) => {
       values.action = action;
       try {
          await agent.CashRegister.spendOrAddMoney(values);
        onClose();
        refetch();
        } catch (error) {
            console.log(error);
        }
    };
    const initialValues = {
        amount: 0,
        note: "",
    };
    return (
        <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
        <Dialog open={open}>
        <DialogTitle textAlign="center">
          <MDTypography variant="h6">
            {action === "add" ? "Mədaxil Et" : "Məxaric Et"}
          </MDTypography>
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
            }}
          >
            <Grid 
              item
              xs={12}
              mt={2}
              md={6}
              >
              <MDBox mb={2}>
                 <MDInput
                    label="Məbləğ"
                    type="number"
                    name="amount"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                    fullWidth
                    required
                    />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                    label="Qeyd"
                    type="text"
                    name="note"
                    value={values.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.note && Boolean(errors.note)}
                    helperText={touched.note && errors.note}
                    fullWidth
                    required
                    />
                </MDBox>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: "1.25rem" }}>
          <Button onClick={onClose}>Bağla</Button>
          <Button color="info" onClick={handleSubmit} variant="contained">
            {action === "add" ? "Əlavə et" : "Məxaric et"}
          </Button>
        </DialogActions>
      </Dialog>
        </Form>
        )}
        </Formik>
    )
}

const AddMoneyToHistoryModal = ({ open, onClose}) => {
  const handleSubmit = async (values,action) => {
    action.setSubmitting(true);
     try {
      await agent.CashRegister.addModenToHistory(values);
      action.setSubmitting(false);
      refetch();
      } catch (error) {
          console.log(error);
      action.setSubmitting(false);
      }
  };
  const initialValues = {
      amount: 0,
      note: "",
      dateTime : new Date()
  };
  return (
      <Formik
      initialValues={initialValues}
      validationSchema={
        yup.object().shape({
          amount: yup.number().required("Məbləğ daxil edilməlidir"),
          note: yup.string().required("Qeyd daxil edilməlidir"),
          dateTime: yup.date().required("Tarix daxil edilməlidir"),
        })
      }
      onSubmit={(values,action) => handleSubmit(values,action)}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
      <Dialog open={open}>
      <DialogTitle textAlign="center">
        <MDTypography variant="h6">
           Əvvələ Mədaxil Et
        </MDTypography>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: "100%",
            minWidth: { xs: "300px", sm: "360px", md: "400px" },
            gap: "1.5rem",
          }}
        >
          <Grid 
            item
            xs={12}
            mt={2}
            md={6}
            >
            <MDBox mb={2}>
               <MDInput
                  label="Məbləğ"
                  type="number"
                  name="amount"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.amount && Boolean(errors.amount)}
                  helperText={touched.amount && errors.amount}
                  fullWidth
                  required
                  />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                  label="Qeyd"
                  type="text"
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.note && Boolean(errors.note)}
                  helperText={touched.note && errors.note}
                  fullWidth
                  required
                  />
              </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
          <MDBox mb={2}>
               <Field
                 disabled={isSubmitting}
                 type="datetime-local"
                 name="dateTime"
                 label="Tarix"
                 as={MDInput}
                 variant="standard"
                 fullWidth
                 value={values.dateTime}
                 onBlur={handleBlur}
                 onChange={handleChange}
               />
          {touched.dateTime && errors.dateTime && errorMessages(errors.dateTime)}
           </MDBox>
           </Grid>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>Bağla</Button>
        <Button color="info"
          disabled={isSubmitting}
         onClick={handleSubmit} 
         variant="contained">
          {isSubmitting ? "Gözləyin..." : "Əlavə et"}
        </Button>
      </DialogActions>
    </Dialog>
      </Form>
      )}
      </Formik>
  )
}

const [addDiscountModalOpen, setAddDiscountModalOpen] = useState(false);
const [addMoneyToHistoryModalOpen, setAddMoneyToHistoryModalOpen] = useState(false);
const [action, setAction] = useState("add");

const handleDeleteRow = async (row) => {
    try {
        await agent.CashRegister.delete(row.original.id);
        refetch();
    } catch (error) {
        console.log(error);
    }
}
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.data ?? []} //data is undefined on first render
        manualFiltering
        manualSorting
        enableRowActions
        enableColumnFilterModes
        enablePagination={false}
        enableStickyFooter
        renderRowActions={({ row, table }) => (
            row.original.date !== "Əvvələ Qalıq"  && (
        <MDBox sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip arrow placement="right" title="Ətraflı">
                 <IconButton onClick={() => {
                  table.setEditingRow(row)
                  }}>
                    <VisibilityIcon />  
                 </IconButton>
                 </Tooltip>
              <Tooltip arrow placement="right" title="Sil">
                 <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                   <Delete />
                 </IconButton>
              </Tooltip>
             </MDBox>

            )
        )}
        renderTopToolbarCustomActions={({ table }) => {
            return (
              <>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                <Button 
                color="info"
                fontWeight="medium"
                borderRadius="4px"
                backgroundColor="info.main"
                textGradient
                variant="contained"
                onClick={() => {
                    setAction("add");
                    setAddDiscountModalOpen(true);
                }}
                >
                 Mədaxil Et
                </Button>
                <Button
                    color="info"
                    fontWeight="medium"
                    borderRadius="4px"
                    backgroundColor="info.main"
                    textGradient
                    variant="contained"
                    onClick={() => {
                        setAction("spend");
                        setAddDiscountModalOpen(true);
                    }}
                >
                  Məxaric Et
                </Button>
                  <Button
                    color="info"
                    fontWeight="medium"
                    borderRadius="4px"
                    backgroundColor="info.main"
                    textGradient
                    variant="contained"
                    onClick={() => setAddMoneyToHistoryModalOpen(true)}
                  >
                    Əvvələ Mədaxil Et
                  </Button>
                  <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                  <MDBox
                    component="span"
                    sx={{
                      display: "inline-block",
                      testAlign: "center",
                      borderRadius: "4px",
                      padding: "0.25rem 0.5rem",
                      backgroundColor:  totalProfit > 0 ? "success.main" : "error.main",
                      color: "white",
                      }}
                     >
                        {/* <MDTypography
                         variant="h6"
                         fontWeight="medium"
                         fontSize="1rem"
                         color="white"
                        >
                        {
                          totalProfit > 0 ? "Mənfəət: " : "Zərər: "
                        }
                        {totalProfit} AZN
                        </MDTypography> */}
                    </MDBox>

                </div>
              </>
            );
          }
        }
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        initialState={{ showColumnFilters: true }}
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        state={{
          // rowSelection,
          columnFilters,
          globalFilter,
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
        <CreateDiscountModal
         open={addDiscountModalOpen} 
         action={action}
         onClose={() => setAddDiscountModalOpen(false)
         } />
        <AddMoneyToHistoryModal
          open={addMoneyToHistoryModalOpen}
          onClose={() => setAddMoneyToHistoryModalOpen(false)}
        />
    </>
  );
};

const CashRegisterReport = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
               Kassa Hesabatı
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example/>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default CashRegisterReport;
