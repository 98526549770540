import React, { useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {
    IconButton,
    Tooltip,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
  } from "@mui/material";
  import {Delete} from "@mui/icons-material";
  import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from "@mui/icons-material/Refresh";
// react query 
import { useQuery } from "@tanstack/react-query";
// yup
import * as yup from "yup";

import dayjs, { Dayjs } from 'dayjs';

import { Formik, Form, Field } from "formik";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import axios from "axios";

import agent from "api/agent";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
//Date Picker Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


const Example = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [isMainWarehouse, setIsMainWarehouse] = useState(true);

  const [startDate, setStartDate] = React.useState(
    new Date()
   );
   const [endDate, setEndDate] = React.useState(
     new Date()
   );



  React.useEffect(() => {
    if(columnFilters.length === 0){
      setColumnFilters([
        {
          id:"Created",
          value: [dayjs(new Date())?.toISOString(), dayjs(new Date())?.toISOString()]
        },
        { 
          id:"IsMain",
          value: "true"
        }
      ])
    }
}, [])



const handleDateChange = (newStartDate, newEndDate) => {
  setStartDate(newStartDate);
  setEndDate(newEndDate);
  const newValue = [newStartDate?.toISOString(), newEndDate?.toISOString()];
  setColumnFilters([
    {
      id:"IsMain",
      value: isMainWarehouse ? "true" : "false"
    },
    { id: 'Created', value: newValue }
  ]);
};

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/admin/Report/inventory-transaction-report",
        "https://api.beautyindustry.works"
      );
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      const response = await axios.get(fetchURL.href, {
        headers: {
          "ngrok-skip-browser-warning": "any",
        },
      });
      return response?.data ?? [];
    },
    keepPreviousData: true,
  });

   React.useMemo( 
    () => {
        if (data?.data) {
          const createdFilter = columnFilters.find(filter => filter.id === "Created");
          const firstCreatedDate = createdFilter?.value[0]?.split("T")[0];
        
          if (firstCreatedDate && data.data[0]?.history !== `${firstCreatedDate} tarixindən öncə`) {
            const newData = {
              "history": `${firstCreatedDate} tarixindən öncə`,
              "remainder": data?.previousRemainder,
            }
            if (data?.previousRemainder > 0) {
              data?.data.unshift(newData);
            }
          }
        }
    },
    [data]
    );
    

   const columns = useMemo(
     () => [
       {
      accessorFn: (row) =>
       // try parse date check
       typeof row?.history === 'string' && !isNaN(Date.parse(row?.history))
        ? new Date(row?.history)
        : row?.history,
        header: "Tarix",
        id: "Created",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
         cell.getValue() instanceof Date ? (
            <MDBox component="span" fontWeight="medium">
               {cell.getValue().toLocaleDateString()}
            </MDBox>
            ) : (
                <MDBox component="span" fontWeight="medium">
                     {cell.getValue()}
                </MDBox>
            )
         , 
         Filter: ({ column }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div>
                <DatePicker
                  label="Başlangıç tarihi"
                  onChange={(newValue) => handleDateChange(newValue, endDate)}
                  value={!isNaN(Date.parse(startDate)) ? dayjs(startDate) : dayjs(new Date())}
                />
                <DatePicker
                  label="Bitiş tarihi"
                  onChange={(newValue) => handleDateChange(startDate, newValue)}
                  value={!isNaN(Date.parse(endDate)) ? dayjs(endDate) : dayjs(new Date())}
                />
              </div>
            </LocalizationProvider>
          );
        },
       },
       {
        accessorKey: "warehouse",
        header: "Anbar",
        id: "Manager.NameSurname",
       },
       {
        accessorKey: "document",
        header: "Sənədin adı",
        id: "TransactionType",
      },
      {
        accessorKey: "productName",
        header: "Məhsul adı",
        id: "Product.Name",
      },
      {
        accessorKey: "income",
        header: "Medaxil",
        id: "Income",
      },
      {
        accessorKey: "expense",
        header: "Məxaric",
        id: "Expense",
      },
      {
        accessorKey: "remainder",
        header: "Qalıq",
        id: "Remainder",
      },
      {
        accessorKey: "customer",
        header: "Müştəri",
        id: "Customer",
      },
     ],
    [totalIncome, totalExpenses, totalBalance]
   );




const handleDeleteRow = async (row) => {
    try {
        await agent.Product.removeTransactionReport(row.original.id);
        refetch();
    } catch (error) {
        console.log(error);
    }
}
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.data ?? []} //data is undefined on first render
        manualFiltering
        manualSorting
        enableRowActions
        enableColumnFilterModes
        enablePagination={false}
        enableStickyFooter
        renderRowActions={({ row, table }) => (
            row.original.date !== "Bu tarixə qədər olan qalıq"  && (
          <MDBox sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Sil">
                 <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                   <Delete />
                 </IconButton>
              </Tooltip>
             </MDBox>
            )
        )}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                
            <FormControlLabel 
              value="true" 
              label="Əsas Anbar"
              control={
              <Radio
                checked={isMainWarehouse}
                onChange={(e) => {
                  setIsMainWarehouse(e.target.value === "true");
                  setColumnFilters([
                     ...columnFilters.filter((filter) => filter.id !== "IsMain" && filter.id !== "IsMain"),
                    {
                      id:"IsMain",
                      value: "true"
                    }
                  ])
                }}
              />}/>

             <FormControlLabel 
              value="false" 
              label="Menecerin Anbarı"
              control={
              <Radio
                checked={!isMainWarehouse}
                onChange={(e) => {
                  setIsMainWarehouse(e.target.value === "true");
                  setColumnFilters([
                      ...columnFilters.filter((filter) => filter.id !== "IsMain" && filter.id !== "IsMain"),
                    {
                      id:"IsMain",
                      value: "false"
                    }
                  ])
                }}
              />}/>    

                <Tooltip arrow title="Yenilə">
                  <IconButton onClick={() => refetch()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          );
        }
      }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        initialState={{ showColumnFilters: true }}
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
    </>
  );
};

const CashRegisterReport = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                   Məhsulların hərəkəti cədvəli
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example/>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default CashRegisterReport;
