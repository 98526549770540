import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import agent from "api/agent";

const initialState = {
  user : null,
  token : null,
};

// login , current user  
export const signInUser  = createAsyncThunk(
    "auth/login",
    async (values, { rejectWithValue }) => {
        try {
            const response = await agent.Account.login(values);
            if (response?.succeeded) {
              localStorage.setItem("token", JSON.stringify(response?.token));
            }
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
  }
);

export const getCurrentUser = createAsyncThunk(
    "auth/currentUser",
    async (values, { rejectWithValue }) => {
        try {
            const response = await agent.Account.currentUser();
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signOutUser: (state, action) => {
            localStorage.removeItem("token");
            state.user = null;
            state.token = null;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
    },
    extraReducers: {
        [signInUser.fulfilled]: (state, action) => {
            state.user = action.payload?.user;
            state.token = action.payload?.token;
        }
    }
});

export const { signOutUser,setToken} = authSlice.actions;
export default authSlice.reducer;




               



