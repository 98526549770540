import React, { useMemo, useState,useCallback } from "react";
import MaterialReactTable from "material-react-table";
import { IconButton, Tooltip, Button, Toolbar } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";

//Http Agent 
import agent from "api/agent";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";

const Example = () => {
  // Create new modal for create new category
  const [createModalOpen, setCreateModalOpen] = useState(false);
  // Table data 
  const [tableData, setTableData] = useState([]);
  // Error, Loading, Fetching states
  const [isError, setIsError] = useState(false);
  // Fetching state 
  const [isFetching, setIsFetching] = useState(false);
  // Validation errors
  const [validationErrors, setValidationErrors] = useState({});

  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [errorSB, setErrorSB] = useState(false);

  const handleCreateNewRow = (values) => {
    tableData.push(values);
    setTableData([...tableData]);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
     values.categoryId = row.original.id;
    if (!Object.keys(validationErrors).length) {
      setIsFetching(true);
      var result = await agent.Category.update(values);
      if (result.succeeded) {
        setSuccessSB(true);
        refetch();
        exitEditingMode(); 
        setIsFetching(false);
      } else {
        setIsFetching(false);
        setErrorSB(true);
      }
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      if (
        !confirm(`
        "${row.original.name}" kateqoriyasını silmək istədiyinizə əminsiniz?
         Kateqoriya silinərsə, ona aid bütün məhsullar da silinəcək.
        `)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      try{
      setIsFetching(true);
       agent.Category.delete(row.original.id).then((response) => {
        if (response.succeeded) {
          setSuccessSB(true);
          tableData.splice(row.index, 1);
          setTableData([...tableData]);
          setIsFetching(false);
        } else {
          setIsFetching(false);
          setErrorSB(true);
        }
      });
      }
      catch(error){
        setIsFetching(false);
        console.log(error);
      }
    },
    [tableData],
  );


 // Get all categories from api
 const GetCategories = async () => {
  try {
    setIsFetching(true);
    const response = await agent.Category.list();
    setTableData(response);
    setIsError(false);
    setIsFetching(false);
  }catch (error) {
    setIsFetching(false);
    setIsError(true);
  }
  };

  const refetch = useCallback(() => {
    GetCategories();
  }, []);


  React.useEffect(() => {
    GetCategories();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Kateqoriya adı",
        id: "Name",
      },
      {
        accessorKey: "productsCount",
        header: "Məhsul sayı",
        columnFilterModeOptions: ["between", "greaterThan", "lessThan"], //only allow these filter modes
        filterFn: "between",
        enableEditing: false,
        id: "ProductsCount",
      },
    ],
    []
  );


  return (
    <>
    <MaterialReactTable
      columns={columns}
      data={tableData ?? []}
      enableColumnFilterModes
      enableColumnOrdering
      enableGrouping
      enablePinning
      enableRowActions
      initialState={{ showColumnFilters: true }}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      editingMode="modal"
      enableEditing
      onEditingRowSave={handleSaveRowEdits}
      onEditingRowCancel={handleCancelRowEdits}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Error loading data",
            }
          : undefined
      }
      renderRowActions={({ row, table }) => (
        <MDBox sx={{ display: 'flex', gap: '1rem' }}>
           <Tooltip arrow placement="right" title="Düzəliş et">
            <IconButton onClick={() => {
             table.setEditingRow(row)
             }}>
              <Edit />  
            </IconButton>
            </Tooltip>
         <Tooltip arrow placement="right" title="Delete">
            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
              <Delete />
            </IconButton>
         </Tooltip>
        </MDBox>
      )}
      renderTopToolbarCustomActions={() => (
        <MDButton
          color="secondary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
        >
          Yeni kateqoriya yarat
        </MDButton>
      )}
      state={{
        showAlertBanner: isError,
        showProgressBars: isFetching,
      }}
    />
   <MDSnackbar
    color={errorSB ? "error" : "success"}
    icon="check"
    title={errorSB ? "Xəta baş verdi" : "Uğurlu əməliyyat"}
    content={errorSB ? "Əməliyyatı yenidən yoxlayın" : "Əməliyyat uğurla yerinə yetirildi"}
    dateTime={new Date().toLocaleString().split(",")[0]}
    open={successSB}
    onClose={closeSuccessSB}
    close={closeSuccessSB}
    bgWhite
  />
     <CreateNewCategoryModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </>
  );
};

export const CreateNewCategoryModal = ({ open, columns, onClose, onSubmit }) => {
  return (
    <>
    </>
  );
}



const Category = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Kateqoriyalar
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default  Category;
