import React, { useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import {
  IconButton,
  Tooltip,
  Toolbar,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik, Form, Field } from "formik";
import RefreshIcon from "@mui/icons-material/Refresh";
import {  Edit, MoveUp, Visibility,Inventory2,Delete } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Ibeauty Admin Dashboard React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import SelectBox from "components/MDSelectBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import axios from "axios";
import agent from "api/agent";
import * as Yup from "yup";

import CircularProgress from '@mui/material/CircularProgress';

// Ibeauty Admin Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const Example = () => {
  const [managers, setManagers] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = React.useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [stockIncreaseModalOpen, setStockIncreaseModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [loading, setLoading] = useState(false);


  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [errorSB, setErrorSB] = useState(false);


  async function fetchCategories() {
    const response = await agent.Category.list();
    const categories = response?.map((category) => {
      return {
        value: category.id,
        label: category.name,
      };
    }); 
    setCategories(categories);
  }
  React.useEffect(() => {
    fetchCategories();
  }, []);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "api/admin/Product/Products",
        "https://api.beautyindustry.works"
      );
      fetchURL.searchParams.set(
        'start',
        `${pagination.pageIndex + 1}`,
      );
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set("globalFilter", globalFilter ?? "");
      fetchURL.searchParams.set("sorting", JSON.stringify(sorting ?? []));

     const managers = await agent.Managers.allmanagers()
     setManagers(managers?.map((item) => item.nameSurname));
  
      const response = await axios.get(fetchURL.href, {
        headers: {
          "ngrok-skip-browser-warning": "any",
        },
      });
      return response.data;
    },
    keepPreviousData: true,
  });


   const columns = useMemo(
     () => [
       {
         accessorKey: "name",
         header: "Məhsulun adı",
         id: "Name",
       },
       {
         accessorKey: "categoryName",
         header: "Məhsulun kategoriyası",
         id: "Category.Name",
         Edit: ({ cell, column, table }) => (
           <Autocomplete
             options={categories ? categories : []}
             getOptionLabel={(option) => option.label}
             renderInput={(params) => (
               <TextField
                 {...params}
                 error={!!validationErrors.CategoryId}
                 helperText={validationErrors.CategoryId}
                 required
                 label={cell.column.header}
                 variant="standard"
               />
             )}
             defaultChecked={categories?.find((category) => category.value === cell.value)}
             onChange={(event, value) => {
               setSelectedCategoryId(value?.value);
             }}
             value={cell.value}
           />
         ),
       },
       {
         accessorKey: "quantity",
         header: "Məhsulun sayı",
         manualFiltering: true,
         Filter: () => null,
         enableSorting: false, 
         id: "Quantity",
       },
       {
         accessorKey: "cost",
         header: "Maya qiyməti",
         columnFilterModeOptions: ["between", "greaterThan", "lessThan"], //only allow these filter modes
         filterFn: "between",
         id: "Cost",
         muiTableBodyCellEditTextFieldProps: {
           error: !!validationErrors.Cost, //highlight mui text field red error color
           helperText: validationErrors.Cost, //show error message in helper text.
           required: true,
           type: "number",
           onChange: (event) => {
             const value = event.target.value;
             //validation logic
             if (!value) {
               setValidationErrors((prev) => ({ ...prev, Cost: "Cost is required" }));
             } else if (value < 1) {
               setValidationErrors({
                 ...validationErrors,
                 Cost: "Cost must be greater than 0",
               });
             } else {
               delete validationErrors.Cost;
               setValidationErrors({ ...validationErrors });
             }
           },
         },
       },
       {
         accessorKey: "price",
         header: "Satış qiyməti",
         columnFilterModeOptions: ["between", "greaterThan", "lessThan"], //only allow these filter modes
         filterFn: "between",
         id: "Price",
       },
       {
         accessorKey: "cashCommission",
         header: "Nağd komissiya",
         columnFilterModeOptions: ["between", "greaterThan", "lessThan"], //only allow these filter modes
         filterFn: "between",
         id: "CashCommission",
       },
     ],
     [managers]
   );

   const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !confirm(`
        "${row.original.name}" məhsulu silmək istədiyinizə əminsiniz?
          Məhsul silinərsə, ona aid bütün satış və ödəniş hesabatlarıda silinəcək!
        `)
      ) {
        return;
      }
      try {
        setLoading(true);
        const response = await agent.Product.delete(row.original.id);
        if (response) {
          setSuccessSB(true);
          const updatedTableData = [...tableData];
          updatedTableData.splice(row.index, 1);
          setTableData(updatedTableData);
          refetch();
        } else {
          setErrorSB(true);
        }
      } catch (error) {
        console.error(error);
        setErrorSB(true);
      } finally {
        setLoading(false);
      }
    },
    [tableData],
  );  

  

const [productId, setProductId] = useState(null);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
        const response = await agent.Product.updateProduct({
          productId: row.original.id,
          name: values.Name,
          categoryId: selectedCategoryId,
          quantity: values.Quantity,
          cost: values.Cost,
          price: values.Price,
          cashCommission: values.CashCommission,
        });
        if (response?.succeeded) {
          setSuccessSB(true);
          refetch();
          exitEditingMode(); 
          data[row.index] = values;
          refetch();
          exitEditingMode();
        } else {
          setErrorSB(true);
          setValidationErrors(response?.errors);
        }
      }
  };
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };
  const rowsPerPageOptions = [];
for (let i = 10; i <= data?.totalCount; i += 10) {
  rowsPerPageOptions.push(i);
}
rowsPerPageOptions.push(data?.totalCount);
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data?.items ?? []} //data is undefined on first render
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualSorting
        manualPagination
        enableRowActions
        enableColumnFilterModes
        editingMode="modal" //default
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        muiTablePaginationProps={{
          rowsPerPageOptions: rowsPerPageOptions,
          showFirstButton: true,
          showLastButton: true,
        }}
        renderRowActions={({ row, table }) => (
          <Toolbar>
            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
              <Delete />
            </IconButton>
            <IconButton
              onClick={() => {
                setProductId(row.original.id);
                setStockIncreaseModalOpen(true);
              }}
            >
              <Inventory2
                sx={{
                  color: "#ff0000",
                }}
              />
            </IconButton>
            <IconButton onClick={() => table.setEditingRow(row)}>
              <Edit
                sx={{
                  color: "orange",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setProductId(row.original.id);
                setCreateModalOpen(true);
              }}
            >
              <MoveUp
                sx={{
                  color: "#1ac90a",
                }}
              />
            </IconButton>
          </Toolbar>
        )}
        muiSelectCheckboxProps={{
          color: "secondary",
        }}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <Tooltip arrow title="Refresh Data">
                  <IconButton onClick={() => refetch()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          );
        }}
        rowCount={data?.totalCount ?? 0}
        state={{
          // rowSelection,
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />

    <MDSnackbar
    color={errorSB ? "error" : "success"}
    icon="check"
    title={errorSB ? "Xəta baş verdi" : "Uğurlu əməliyyat"}
    content={errorSB ? "Əməliyyatı yenidən yoxlayın" : "Əməliyyat uğurla yerinə yetirildi"}
    dateTime={new Date().toLocaleString().split(",")[0]}
    open={successSB}
    onClose={closeSuccessSB}
    close={closeSuccessSB}
    bgWhite
  />

      <HandOverProductModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        productId={productId}
      />

      <IncreaseStockModal
        open={stockIncreaseModalOpen}
        onClose={() => setStockIncreaseModalOpen(false)}
        productId={productId}
      />
    </>
  );
};;

const HandOverProductModal = ({ open, onClose , productId}) => {
  const [managers, setManagers] = useState([])

  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [errorSB, setErrorSB] = useState(false);

  React.useEffect(() => {
    agent.Managers.allmanagers().then((response) => {
      const managers = response?.map((manager) => {
        return {
          value: manager.id,
          label: manager.userName,
        };
      });
      setManagers(managers);
    });
  }, []);

  const [handleName, setHandleName] = useState(null);
  const handleSubmit =async (values) => {
    values.productId = productId;
    try {
      const response = await agent.Product[handleName](values);
      if (response?.succeeded) {
        setSuccessSB(true);
        onClose();
      } else {
        alert(response.errors[0])
        setErrorSB(true);
      }
    }catch (error) {
      setErrorSB(true);
      console.log(error);
    }
  };

   const initialValues = {
    managerId: "",
    toHistory: false,
    dateTime: new Date(),
    count: 0,
   };

   const errorMessages = (error) => {
    return (
       <MDTypography 
        variant="body2"
        color="error"
        textTransform="capitalize"
        >
        {error}
        </MDTypography>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        Yup.object({
          managerId: Yup.string().required("Menecer seçilməlidir"),
          count: Yup.number().required("Məhsul sayı daxil edilməlidir").min(1, "Məhsul sayı 1-dən kiçik ola bilməz"),
          toHistory: Yup.boolean("Seçim edilməlidir"),
          dateTime: Yup.date(),
        })
      }
      onSubmit={async (values) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
          <Dialog open={open}>
            <DialogTitle textAlign="center">
              <MDTypography variant="h6">Anbar Transfer</MDTypography>
            </DialogTitle>
            <DialogContent>
              <Stack
                sx={{
                  width: "100%",
                  minWidth: { xs: "300px", sm: "360px", md: "400px" },
                  gap: "1.5rem",
                }}
              >
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      name="managerId"
                      label="Menecerlər"
                      component={SelectBox}
                      options={managers ? managers : []}
                      onChange={(value) => setFieldValue("managerId", value)}
                    />
                    {touched.managerId && errors.managerId && errorMessages(errors.managerId)}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox mb={2}>
                    <Field
                      name="ToHistory"
                      label="Keçmişə əlavə et"
                      component={SelectBox}
                      options={[
                        { value: false, label: "Xeyr" },
                        { value: true, label: "Bəli" },
                      ]}
                      onChange={(value) => setFieldValue("toHistory", value)}
                    />
                    {touched.toHistory && errors.toHistory && errorMessages(errors.toHistory)}
                  </MDBox>
                </Grid>
                  {values.toHistory && (
                    <Grid item xs={12} md={6}>
                      <MDBox mb={2}>
                        <Field
                          type="datetime-local"
                          name="dateTime"
                          label="Tarix"
                          as={MDInput}
                          variant="standard"
                          fullWidth
                          value={values.dateTime}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </MDBox>
                    {touched.dateTime && errors.dateTime && errorMessages(errors.dateTime)}
                    </Grid>
                  )}
                <Grid item xs={12} md={4}>
                  <MDBox mb={2}>
                    <Field
                      type="number"
                      name="count"
                      label="Məhsul sayı"
                      as={MDInput}
                      variant="standard"
                      fullWidth
                      value={values.count}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </MDBox>
                </Grid>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }}>
              <Button onClick={onClose}>Cancel</Button>

              <Button color="info" 
              disabled={isSubmitting}
              onClick={
                () => {
                  setHandleName("handoverProduct");
                  handleSubmit();
                }
              } variant="contained">
                {isSubmitting && handleName === "handoverProduct" ?
                 <CircularProgress size={24}
                 sx={{ color: "green", position: "absolute", top: "50%", 
                 left: "50%", marginTop: "-12px", marginLeft: "-12px" }} /> :
                 "Əlavə Et" 
                  }
              </Button>
              <Button
                sx={{
                  color: "red",
                }}
                disabled={isSubmitting}
                color="error"
                onClick={
                  () => {
                    setHandleName("returnProduct");
                    handleSubmit();
                  }
                }
                variant="contained"
              >
                {isSubmitting && handleName === "returnProduct" ?
                <CircularProgress size={24} 
                sx={{ color: "green", position: "absolute", top: "50%",
               left: "50%", marginTop: "-12px", marginLeft: "-12px" }} /> :
                 "Anbara geri qaytar" 
                 }
              </Button>
            </DialogActions>
          </Dialog>
    <MDSnackbar
    color={errorSB ? "error" : "success"}
    icon="check"
    title={errorSB ? "Xəta baş verdi" : "Uğurlu əməliyyat"}
    content={errorSB ? "" : ""}
    dateTime={new Date().toLocaleString().split(",")[0]}
    open={errorSB || successSB}
    onClose={closeSuccessSB || setErrorSB}
    close={closeSuccessSB || setErrorSB}
    bgWhite
    />
        </Form>
      )}
    </Formik>
  );
};

const IncreaseStockModal = ({ open, onClose , productId}) => {
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [errorSB, setErrorSB] = useState(false);

  const handleSubmit = async (values) => {
    values.productId = productId;
    try{
      const response = await agent.Product.updateStock(values);
      if (response) {
        setSuccessSB(true);
        onClose();
      }
    }catch(error){
      setErrorSB(true);
      console.log(error);
    }
  };

  const initialValues = {
    quantity: 1,
    updateType: "Increase",
    dateTime: new Date(),
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      // async onSubmit
     onSubmit={async (values) => await handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,  
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form>
            <Dialog open={open}>
              <DialogTitle textAlign="center">
                <MDTypography variant="h6">
                   Stokun yenilənməsi
                </MDTypography>
              </DialogTitle>
              <DialogContent>
                <Stack
                  sx={{
                    width: "100%",
                    minWidth: { xs: "300px", sm: "360px", md: "400px" },
                    gap: "1.5rem",
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <Field
                        type="number"
                        name="quantity"
                        label="Məhsul sayı"
                        as={MDInput}
                        variant="standard"
                        fullWidth
                        value={values.quantity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <Field
                        name="updateType"
                        label="Əməliyyat növü"
                        component={SelectBox}
                        options={[
                          { value: "Increase", label: "Artır" },
                          { value: "Decrease", label: "Azalt" },
                        ]}
                        onChange={(value) => setFieldValue("updateType", value)}
                      />
                      {touched.updateType && errors.updateType && errorMessages(errors.updateType)}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <Field
                        type="datetime-local"
                        name="dateTime"
                        label="Tarix"
                        as={MDInput}
                        variant="standard"
                        fullWidth
                        value={values.dateTime}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </MDBox>
                  </Grid>
                </Stack>
              </DialogContent>
              <DialogActions sx={{ p: "1.25rem" }}>
                <Button onClick={onClose}>
                  Bağla
                </Button>
                <Button 
                color="info"
                 onClick={handleSubmit} 
                 variant="contained"
                 disabled={isSubmitting}
                 >
                 {!isSubmitting ? "Yenilə" :
                  <CircularProgress size={24}
                   sx={{ color: "green", position: "absolute", top: "50%", 
                   left: "50%", marginTop: "-12px", marginLeft: "-12px" }} 
                  />}
                </Button>
              </DialogActions>
            </Dialog>
    <MDSnackbar
    color={errorSB ? "error" : "success"}
    icon="check"
    title={errorSB ? "Xəta baş verdi" : "Uğurlu əməliyyat"}
    content={errorSB ? "Stok Yenilənərkən xəta baş verdi" : "Stok Yeniləndi"}
    dateTime={new Date().toLocaleString().split(",")[0]}
    open={errorSB || successSB}
    onClose={closeSuccessSB || setErrorSB}
    close={closeSuccessSB || setErrorSB}
    bgWhite
    />
     </Form>
          
      )}
    </Formik>
  );
};



const Products = () => (
  <DashboardLayout>
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Məhsullar
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Example/>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  </DashboardLayout>
);

export default Products;
